import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import {
  Item,
  FancyHeader2,
  DetailTemplate
} from '../components/common'
import {
  ItemCarousel
} from '../components/carousel'

class OutfitTemplate extends React.Component {
  render () {
    const collection = get(this.props, 'data.contentfulCollection')
    return (
      <DetailTemplate
        title={collection.title}
        subtitle={collection.subtitle}
        location={this.props.location}
        comingSoon={collection.comingSoon}
        gallery={collection.images}
        review={collection.review}>
        {collection.products && (
          <div>
            <FancyHeader2>Get the Look</FancyHeader2>
            <ItemCarousel gallery={collection.products.map(product => {
              const url = `/product/${ product.slug }/`
              return (
                <Item
                  comingSoon={product.comingSoon}
                  url={url}
                  key={url}
                  /* title={product.title} */
                  mobileDivisor={2}
                  image={get(product, 'images[0].fixed')}
                  imageTitle={get(product, 'images[0].title')}
                />
              )
            })} />
          </div>
        )}
      </DetailTemplate>
    )
  }
}

export default OutfitTemplate

export const pageQuery = graphql`
  query CollectionBySlug($slug: String!) {
    contentfulCollection(slug: { eq: $slug }) {
      title
      subtitle
      comingSoon
      images {
        title
        image: fixed(width:1200) {
          width
          height
          src
        }
        thumb: fixed(width:800) {
          width
          height
          src
        }
      }
      review {
        review
        childMarkdownRemark {
          html
        }
      }
      products {
        title
        slug
        comingSoon
        images {
          title
          fixed(width:400) {
            width
            height
            src
          }
        }
      }
    }
  }
`
